export class User {
    id: string;
    name: string;
    email: string;
    phone: string;
    postCode: string;
    streetName: string;
    streetNumber: string;
    town: string;
}
