import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, CanLoad, Route, UrlSegment } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';

@Injectable()
export class PrivacyGuard implements CanLoad {
  constructor(private authService: AuthService, private router: Router) { }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    const currentUser = this.authService.currentUserValue;
    if (currentUser) {
      if (currentUser.redirectUrl && currentUser.redirectUrl.length > 0 && currentUser.redirectUrl.length !=='/agent') {
        this.router.navigateByUrl(currentUser.redirectUrl);
        return false;
      }
      return true;
    };
    return true;
  }
}
