import { MessageAttachment } from './../models/message-attachment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Message } from '../models/message';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  baseUrl = '/api/message/';

  constructor(private http: HttpClient) { }

  getInbox(): Observable<Message[]> {
    return this.http.get<Message[]>(this.baseUrl + 'inbox');
  }

  getById(id: string): Observable<Message> {
    return this.http.get<Message>(this.baseUrl + id);
  }

  getSent(): Observable<Message[]> {
    return this.http.get<Message[]>(this.baseUrl + 'outbox');
  }

  getArchived(): Observable<Message[]> {
    return this.http.get<Message[]>(this.baseUrl + 'archived');
  }

  sendMessage(frmData: any): Observable<Message> {
    return this.http.post<Message>(this.baseUrl + 'send', frmData);
  }

  archiveMessage(dialogueId: string): Observable<Message> {
    return this.http.post<Message>(this.baseUrl + 'archive', { id: dialogueId});
  }

  getDocumentsByDialogueId(id: string): Observable<MessageAttachment[]> {
    return this.http.get<MessageAttachment[]>(this.baseUrl + id + '/documents');
  }

  addMessageLabel(dialogueId: string, labelName: string) {
    return this.http.patch(this.baseUrl + 'addmessagelabel', { dialogueId, labelName });
  }

  getUnreadMessageCount(): Observable<number> {
    return this.http.get<number>(this.baseUrl + 'getunreadmessagecount');
  }

  markAsRead(id: string) {
    return this.http.post(this.baseUrl + 'markasread', {id});
  }

}
