import { Routes } from '@angular/router';
import { AgentGuard } from '../../guards/agent.guard';
import { AuthGuard } from '../../guards/auth.guard';
import { AgentLayoutComponent } from '../../layouts/agent-layout/agent-layout.component';
import { HomeLayoutComponent } from '../../layouts/home-layout.component';
import { LoginLayoutComponent } from '../../layouts/login-layout.component';
import { Role } from '../../models/role';

export const egroutes: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
    canActivate: [AgentGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('../../default-dashboard/default-dashboard.module').then(mod => mod.DefaultDashboardModule),
      }, {
        path: 'invoices',
        loadChildren: () => import('../../invoice/invoice.module').then(mod => mod.InvoiceModule),
        canLoad: [AuthGuard]
      }, {
        path: 'policies',
        loadChildren: () => import('../../policy/policy.module').then(mod => mod.PolicyModule),
        canLoad: [AuthGuard]
      }, {
        //  path: 'documents',
        //  loadChildren: () => import('./document/document.module').then(mod => mod.DocumentModule)
        // }, {
        path: 'profile',
        loadChildren: () => import('../../profile/profile.module').then(mod => mod.ProfileModule),
        canLoad: [AuthGuard]
      }, {
        path: 'messages',
        loadChildren: () => import('../../message/message.module').then(mod => mod.MessageModule),
        canLoad: [AuthGuard]
      }, {
        path: 'quotes',
        loadChildren: () => import('../../quote/quote.module').then(mod => mod.QuoteModule),
        canLoad: [AuthGuard]
      }, {
        path: 'customer-management',
        loadChildren: () => import('../../customer-management/customer-management.module').then(mod => mod.CustomerManagementModule),
        canLoad: [AuthGuard]
      }
    ]
  },
  //{
  //  path: 'admin',
  //  component: AdminLayoutComponent,
  //  children: [
  //    {
  //      path: '',
  //      loadChildren: () => import('./admin/admin-home/admin-home.module').then(mod => mod.AdminHomeModule),
  //    }
  //  ]
  //},
  {
    path: 'agent',
    component: AgentLayoutComponent,
    data: { roles: [Role.Representative] },
    children: [{
      path: '',
      loadChildren: () => import('../../agent/agent.module').then(mod => mod.AgentModule)
    }]
  },
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () => import('../../login-selection/login-selection.module').then(mod => mod.LoginSelectionModule)
      }
    ]
  }
];
