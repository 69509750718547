import { AuthService } from 'src/app/services/auth.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../models/user';
import { UserService } from '../../services/user.service';
import { Customer } from '../../models/customer.model';
import { AgentService } from '../../services/agent.service';
import { TenantConfigService } from '../../services/tenant-config.service';
import { Subscription } from 'rxjs';
import { TenantConfig } from '../../models/tenant-config.model';

@Component({
  selector: 'app-agent-layout',
  templateUrl: './agent-layout.component.html',
  styleUrls: ['./agent-layout.component.scss']
})
export class AgentLayoutComponent implements OnInit, OnDestroy {
  user = new User();
  customers: Customer[] = new Array<Customer>()
  date: Date = new Date();
  logoUrl: string;
  tenantConfig: TenantConfig;

  constructor(private authService: AuthService,
    private userService: UserService,
    private agentService: AgentService,
    private tenantConfigService: TenantConfigService,
    private router: Router) {
    
    this.tenantConfig = this.tenantConfigService.getConfig();
  }

  ngOnInit() {
    this.logoUrl = `/assets/tenants/${this.tenantConfig.key}/logo.jpg`;
  }

  logout() {
    this.authService.logout().subscribe(() => {

      this.router.navigateByUrl('/');
    });
  }

  ngOnDestroy(): void {
  }


}
