import { Routes } from '@angular/router';
import { AgentGuard } from '../../guards/agent.guard';
import { AuthGuard } from '../../guards/auth.guard';
import { PrivacyGuard } from '../../guards/privacy.guard';
import { AgentLayoutComponent } from '../../layouts/agent-layout/agent-layout.component';
import { HomeLayoutComponent } from '../../layouts/home-layout.component';
import { LoginLayoutComponent } from '../../layouts/login-layout.component';
import { Role } from '../../models/role';

export const rcroutes: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
    canActivate: [AgentGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./dashboard/dashboard.module').then(mod => mod.DashboardModule),
      }, {
        path: 'bidrag',
        loadChildren: () => import('../../contribution/contribution.module').then(mod => mod.ContributionModule),
        canLoad: [AuthGuard]
      }, {
        path: 'profil',
        loadChildren: () => import('./profile/profile.module').then(mod => mod.ProfileModule),
        canLoad: [AuthGuard]
      }
      , {
        path: 'mine-samtykker',
        loadChildren: () => import('./privacy-settings/privacy-settings.module').then(mod => mod.PrivacySettingsModule),
        canLoad: [AuthGuard]
      }, {
        path: 'samtykke',
        loadChildren: () => import('./privacy-settings/privacy-settings.module').then(mod => mod.PrivacySettingsModule),
        canLoad: [AuthGuard]
      }
    ]
  },
  //{
  //  path: 'admin',
  //  component: AdminLayoutComponent,
  //  children: [
  //    {
  //      path: '',
  //      loadChildren: () => import('./admin/admin-home/admin-home.module').then(mod => mod.AdminHomeModule),
  //    }
  //  ]
  //},
  {
    path: 'agent',
    component: AgentLayoutComponent,
    data: { roles: [Role.Representative] },
    children: [{
      path: '',
      loadChildren: () => import('../../agent/agent.module').then(mod => mod.AgentModule)
    }]
  },
  {
    path: 'auth',
    component: LoginLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('../../login-selection/username-password-login/username-password-login.module').then(mod => mod.UsernamePasswordLoginModule)
      }
    ]
  }
];
