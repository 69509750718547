import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TenantConfig } from '../models/tenant-config.model';
import { TenantConfigService } from '../services/tenant-config.service';

@Component({
  selector: 'app-login-layout',
  templateUrl: 'login-layout.component.html',
  styles: []
})
export class LoginLayoutComponent implements OnInit, OnDestroy {
  date: Date = new Date();
  logoUrl = "";
  name = "";
  tenantConfig: TenantConfig;

  constructor(private tenantConfigService: TenantConfigService) {
    this.tenantConfig = this.tenantConfigService.getConfig();
    this.logoUrl = `/assets/tenants/${this.tenantConfig.key}/logo.jpg`;
    this.name = this.tenantConfig.name;
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }

}
