import { ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {

  private breadcrumbUpdatedAnnouncedSource = new Subject<ActivatedRouteSnapshot>();
  breadcrumbUpdatedAnnounced$ = this.breadcrumbUpdatedAnnouncedSource.asObservable();

  constructor() { }

  updateBreadcrumb(data: ActivatedRouteSnapshot) {
    this.breadcrumbUpdatedAnnouncedSource.next(data);
  }

}
