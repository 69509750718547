import { UserService } from '../services/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { User } from '../models/user';
import { LoggedInUser } from '../models/logged-in-user.model';
import { AgentService } from '../services/agent.service';
import { filter, Subscription } from 'rxjs';
import { TenantConfigService } from '../services/tenant-config.service';
import { TenantConfig } from '../models/tenant-config.model';
import { MessageService } from '../services/message-service';

@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html',
  styles: []
})
export class HomeLayoutComponent implements OnInit, OnDestroy {
  user = new User();
  date: Date = new Date();
  loggedInUser: LoggedInUser = new LoggedInUser();
  logoUrl: string;
  tenantConfig: TenantConfig;
  profileLink: string;
  unreadMessageCount: number;

  constructor(private authService: AuthService,
    private userService: UserService,
    private agentService: AgentService,
    private messageService: MessageService,
    private tenantConfigService: TenantConfigService,
    private router: Router) {

    this.tenantConfig = this.tenantConfigService.getConfig();

    //this.tenantConfig.modules.forEach((module) => {
    //  console.log(module);
    //});

    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      if (this.loggedInUser !== null && this.tenantConfig.modules.find(x => x.name === 'Meldinger')) {
        this.getUnreadCount();
      }
    });
  }

  ngOnInit() {

    this.userService.get().subscribe((user) => {
      this.user = user;
    });

    if (this.tenantConfig.modules.find(x => x.name === 'Meldinger')) {
      this.getUnreadCount();
    }


    this.authService.currentUser.subscribe((loggedInUser) => {
      this.loggedInUser = loggedInUser;
    });

    this.logoUrl = `/assets/tenants/${this.tenantConfig.key}/logo.jpg`;

    let profileModule = this.tenantConfig.modules.find(x => x.name === 'Profil');

    this.profileLink = profileModule.url;

  }

  getUnreadCount() {
    this.messageService.getUnreadMessageCount().subscribe((count) => {
      this.unreadMessageCount = count;
    });

  }

  logout() {
    this.authService.logout().subscribe(() => {
      this.router.navigate(['/auth']);
    });
  }
  ngOnDestroy(): void {
  }

}
