import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserActivityRequest } from '../models/user-activity-request';

@Injectable({
  providedIn: 'root'
})
export class UserActivityService {
  baseUrl = '/api/useractivity/';

  constructor(private http: HttpClient) { }

  record(userActivity: UserActivityRequest) {
    return this.http.post(this.baseUrl + 'record', userActivity).subscribe();
  }

}
