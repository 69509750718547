import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TenantConfig } from '../models/tenant-config.model';

@Injectable({
  providedIn: 'root'
})
export class TenantService {
  baseUrl = '/api/tenant/';

  constructor(private http: HttpClient) { }

  getConfig(): Observable<TenantConfig> {
    return this.http.get<TenantConfig>(this.baseUrl);
  }

}
