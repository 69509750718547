import { PasswordUpdate } from '../models/password-update';
import { User } from '../models/user';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GuaranteeFrameModel } from '../models/guarantee-frame.model';
import { PrivacySettings } from '../models/privacy-settings';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  baseUrl = '/api/user/';

  constructor(private http: HttpClient) { }

  get(): Observable<User> {
    return this.http.get<User>(this.baseUrl);
  }

  getGuaranteeFrame(): Observable<GuaranteeFrameModel> {
    return this.http.get<GuaranteeFrameModel>(this.baseUrl + 'guaranteeframe');
  }

  getPrimaryContact(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'getprimarycontact');
  }

  getPrivacySettings(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'getprivacysettings');
  }

  update(model: User) {
    return this.http.post(this.baseUrl + 'updateprofile', model);
  }

  updatePrivacySettings(model: PrivacySettings) {
    return this.http.post(this.baseUrl + 'updateprivacysettings', model);
  }

  changePassword(model: PasswordUpdate) {
    return this.http.post(this.baseUrl + 'changepassword', model);
  }

  getLabels(): any {
    return this.http.get<string[]>(this.baseUrl + 'labels');
  }

  addLabel(label: any) {
    return this.http.put(this.baseUrl + 'addlabel', label);
  }

}
