import { Routes } from '@angular/router';
import { AuthGuard } from '../../guards/auth.guard';
import { PrivacyGuard } from '../../guards/privacy.guard';
import { AgentLayoutComponent } from '../../layouts/agent-layout/agent-layout.component';
import { HomeLayoutComponent } from '../../layouts/home-layout.component';
import { LoginLayoutComponent } from '../../layouts/login-layout.component';
import { Role } from '../../models/role';

export const aoroutes: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'hjem',
        pathMatch: 'full'
      },
      {
        path: 'hjem',
        loadChildren: () => import('./dashboard/ao-dashboard.module').then(mod => mod.AODashboardModule),
        canLoad: [PrivacyGuard, AuthGuard]
      }, {
        path: 'faktura',
        loadChildren: () => import('../../invoice/invoice.module').then(mod => mod.InvoiceModule),
        canLoad: [PrivacyGuard, AuthGuard]
      }, {
        path: 'skade',
        loadChildren: () => import('./ao-claims/ao-claims.module').then(mod => mod.AoClaimsModule),
        canLoad: [PrivacyGuard, AuthGuard]
      }, {
        path: 'forsikringer',
        loadChildren: () => import('../../policy/policy.module').then(mod => mod.PolicyModule),
        canLoad: [PrivacyGuard, AuthGuard]
      }, {
        path: 'dokumenter',
        loadChildren: () => import('../../document/document.module').then(mod => mod.DocumentModule),
        canLoad: [PrivacyGuard, AuthGuard]
         }, {
        path: 'profil',
        loadChildren: () => import('./ao-profile/ao-profile.module').then(mod => mod.AoProfileModule),
        canLoad: [PrivacyGuard, AuthGuard]
      }, {
        path: 'mine-samtykker',
        loadChildren: () => import('./ao-privacy-settings/ao-privacy-settings.module').then(mod => mod.AoPrivacySettingsModule),
        canLoad: [PrivacyGuard, AuthGuard]
      }, {
        path: 'samtykke',
        loadChildren: () => import('./ao-initial-settings/ao-initial-settings.module').then(mod => mod.AoInitialSettingsModule),
        canLoad: [AuthGuard]
      }
    ]
  },
  {
    path: 'agent',
    component: AgentLayoutComponent,
    data: { roles: [Role.Representative] },
    children: [{
      path: '',
      loadChildren: () => import('../../agent/agent.module').then(mod => mod.AgentModule)
    }]
  },
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () => import('../../login-selection/login-selection.module').then(mod => mod.LoginSelectionModule)
      }
    ]
  },
  //{
  //  path: 'admin',
  //  component: AdminLayoutComponent,
  //  children: [
  //    {
  //      path: '',
  //      loadChildren: () => import('./admin/admin-home/admin-home.module').then(mod => mod.AdminHomeModule),
  //    }
  //  ]
  //},
  { path: '**', redirectTo: '', pathMatch: 'full' }
];
