import { AuthorizationInterceptor } from './interceptors/authorization.interceptor';
import { LoginLayoutComponent } from './layouts/login-layout.component';
import { HomeLayoutComponent } from './layouts/home-layout.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, APP_INITIALIZER, Injector } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { registerLocaleData } from '@angular/common';
import localeNb from '@angular/common/locales/nb';

registerLocaleData(localeNb, 'nb');

//import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuard } from './guards/auth.guard';
import { NgxNotifierModule } from 'ngx-notifier';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, ActivationEnd, RouterModule, Routes, ROUTES } from '@angular/router';
import { BreadcrumbService } from './services/breadcrumb.service';
import { filter } from 'rxjs/operators';
import { AgentLayoutComponent } from './layouts/agent-layout/agent-layout.component';
import { AuthService } from './services/auth.service';
import { AgentGuard } from './guards/agent.guard';
import { PrivacyGuard } from './guards/privacy.guard';
import { TenantConfigService } from './services/tenant-config.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { from, Observable } from 'rxjs';
import { aoroutes } from './tenants/ao/routes';
import { siroutes } from './tenants/si/routes';
import { egroutes } from './tenants/eg/routes';
import { rcroutes } from './tenants/rc/routes';
//import { ruroutes } from './tenants/ru/routes';
import { TenantService } from './services/tenant.service';
import { AcceptQuoteComponent } from './app/quote/accept-quote/accept-quote.component';
import { AdminGuard } from './guards/admin.guard';


export function initApp(injector: Injector, tenantConfigService: TenantConfigService) {
  return async () => {
    await tenantConfigService.load().then(() => {
      const router: Router = injector.get(Router);
      switch (tenantConfigService.getConfig().key) {
        case "eg":
          router.resetConfig(egroutes);
          break;
        case "ao":
          router.resetConfig(aoroutes);
          break;
        case "si":
          router.resetConfig(siroutes);
          break;
        case "ru":
          router.resetConfig(rcroutes);
          break;
        case "rc":
          router.resetConfig(rcroutes);
          break;
      }
      router.navigateByUrl(window.location.pathname + window.location.search);
    });
  }
}

export class LazyTranslateLoader implements TranslateLoader {
  constructor(private tenantKey: string) {
  }

  getTranslation(lang: string): Observable<any> {
    const deflang = from(import('../i18n/' + lang + '.json'));
    const tenantlang = from(import('../i18n/' + this.tenantKey + '-' + lang + '.json'));

    if (tenantlang) {
      console.log("cp-def", deflang);
      return Object.assign(deflang, tenantlang);
    }
    return deflang;
  }
}

export function LazyTranslateLoaderFactory(_tenantConfigService: TenantConfigService) {

  return new LazyTranslateLoader(_tenantConfigService.getConfig().key);
}

@NgModule({
  declarations: [
    AppComponent,
    HomeLayoutComponent,
    LoginLayoutComponent,
    AgentLayoutComponent,
    AcceptQuoteComponent,
  ],
  imports: [
    RouterModule.forRoot([], { relativeLinkResolution: 'legacy', initialNavigation: 'enabledNonBlocking' }),
    TranslateModule.forRoot({
      loader: {
        useFactory: LazyTranslateLoaderFactory,
        provide: TranslateLoader,
        deps: [TenantConfigService]
      }
    }),
    BrowserModule,
    //AppRoutingModule,
    NgbModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxNotifierModule
  ],
  providers: [
    AuthService,
    AuthGuard,
    AgentGuard,
    AdminGuard,
    PrivacyGuard,
    TenantService,
    TenantConfigService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'nb-NO' },
    { provide: APP_INITIALIZER, useFactory: initApp, deps: [Injector, TenantConfigService, TenantService, Router], multi: true },
    //{ provide: ROUTES, useFactory: initRoutes, multi: true, deps: [TenantConfigService] }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private router: Router, private breadcrumbService: BreadcrumbService) {
    this.router.events.pipe(filter(event =>
      event instanceof ActivationEnd && event.snapshot.children.length === 0)).subscribe((event: ActivationEnd) => {
        this.breadcrumbService.updateBreadcrumb(event.snapshot);
      });
  }
}
