import { PasswordReset } from './../models/password-reset';
import { UserRegistration } from './../models/user-registration';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoginModel } from '../models/loginmodel';
import { Register, RegisterFull } from '../models/register';
import { RequestPassword } from '../models/request-password';
import { VerifyToken } from '../models/verify-token';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { CompleteRegistration } from '../models/complete-registration';
import { CompleteResetPassword } from '../models/complete-reset-password';
import { LoggedInUser } from '../models/logged-in-user.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  baseUrl = '/api/auth/';
  private currentUserSubject: BehaviorSubject<LoggedInUser>;
  public currentUser: Observable<LoggedInUser>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<LoggedInUser>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): LoggedInUser {
    return this.currentUserSubject.value;
  }

  login(model: LoginModel) {
    return this.http.post<any>(`${this.baseUrl}login`, model, { withCredentials: true })
      .pipe(map((loggedInUser: LoggedInUser) => {
        // login successful if there's a jwt token in the response
        if (loggedInUser && loggedInUser.userId) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(loggedInUser));
          this.currentUserSubject.next(loggedInUser);
        }
        return loggedInUser;
      }));

  }

  adminLogin(model: LoginModel) {
    return this.http.post<any>(`${this.baseUrl}adminlogin`, model, { withCredentials: true })
      .pipe(map((loggedInUser: LoggedInUser) => {
        // login successful if there's a jwt token in the response
        if (loggedInUser && loggedInUser.userId) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(loggedInUser));
          this.currentUserSubject.next(loggedInUser);
        }
        return loggedInUser;
      }));

  }

  updateCustomer() {
    return this.http.get<LoggedInUser>(`${this.baseUrl}loggedinuser`)
      .pipe(map((loggedInUser: LoggedInUser) => {
        // login successful if there's a jwt token in the response
        if (loggedInUser && loggedInUser.userId) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(loggedInUser));
          this.currentUserSubject.next(loggedInUser);
        }
        return loggedInUser;
      }));
  }

  logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    return this.http.post(this.baseUrl + 'logout', {}, {});
  }

  register(model: Register): Observable<boolean> {
    return this.http.post<boolean>(this.baseUrl + 'register', model);
  }

  registerFull(model: RegisterFull) {
    return this.http.post(this.baseUrl + 'createcustomer', model);
  }

  requestPassword(model: RequestPassword) {
    return this.http.post(this.baseUrl + 'requestpassword', model);
  }

  verifyEmail(model: VerifyToken): Observable<UserRegistration> {
    return this.http.post<UserRegistration>(this.baseUrl + 'verifyemail', model);
  }

  verifyPassword(model: VerifyToken): Observable<PasswordReset> {
    return this.http.post<PasswordReset>(this.baseUrl + 'resetpassword', model);
  }

  completeRegistration(model: CompleteRegistration) {
    return this.http.post(this.baseUrl + 'completeregistration', model);
  }

  completeResetPassword(model: CompleteResetPassword) {
    return this.http.post(this.baseUrl + 'completepasswordreset', model);
  }

  getIdfyUrl(mobile: boolean) {
    return this.http.get(this.baseUrl + 'getidfyurl?mobile=' + mobile);
  }
  getVippsUrl() {
    return this.http.get(this.baseUrl + 'getvippsurl');
  }
  verifyVipps(code: string, state: string) {
    return this.http.get(this.baseUrl + 'vipps-verify?code=' + code + '&state=' + state).pipe(map((loggedInUser: LoggedInUser) => {
      // login successful if there's a jwt token in the response
      if (loggedInUser && loggedInUser.userId) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(loggedInUser));
        this.currentUserSubject.next(loggedInUser);
      }
      return loggedInUser;
    }));
  }
  verfiyLogin(requestId: string) {
    return this.http.get(this.baseUrl + 'verifylogin?requestId=' + requestId).pipe(map((loggedInUser: LoggedInUser) => {
      // login successful if there's a jwt token in the response
      if (loggedInUser && loggedInUser.userId) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(loggedInUser));
        this.currentUserSubject.next(loggedInUser);
      }
      return loggedInUser;
    }));
  }

}
