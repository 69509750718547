import { Injectable } from '@angular/core';
import { config } from 'rxjs';
import { TenantConfig } from '../models/tenant-config.model';
import { TenantService } from './tenant.service';

@Injectable()
export class TenantConfigService {
  public load() {

    return this.tenantService.getConfig()
      .toPromise()
      .then(data => {
        this.tenantConfig = data;
      });
  }

  getConfig() {
    return this.tenantConfig;
  }

  private tenantConfig: TenantConfig;

  constructor(private tenantService: TenantService) {
  }
}
