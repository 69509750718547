import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, CanLoad, Route, UrlSegment, CanActivate, UrlTree } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
import { AgentService } from '../services/agent.service';
import { map } from 'rxjs/operators';
import { Role } from '../models/role';

@Injectable()
export class AgentGuard implements CanActivate {
  constructor(private authService: AuthService, private agentService: AgentService, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const currentUser = this.authService.currentUserValue;
    if (currentUser) {
      if (currentUser.role === Role.Representative || currentUser.role === Role.Admin) {
        return this.agentService.checkSelectedCustomer(this.authService.currentUserValue.userId).pipe(map((hasSelectedCustomer: boolean) => {
          
          if (!hasSelectedCustomer) {
            this.router.navigateByUrl("/agent");
          }
          return hasSelectedCustomer;
        }));
      }
      return true;
    }
    return true;
  }
}
