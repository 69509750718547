import { Routes } from '@angular/router';
//import { AdminComponent } from '../../admin/admin/admin.component';
import { AuthGuard } from '../../guards/auth.guard';
import { PrivacyGuard } from '../../guards/privacy.guard';
import { AgentLayoutComponent } from '../../layouts/agent-layout/agent-layout.component';
import { HomeLayoutComponent } from '../../layouts/home-layout.component';
import { LoginLayoutComponent } from '../../layouts/login-layout.component';
import { Role } from '../../models/role';

export const siroutes: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'hjem',
        pathMatch: 'full'
      },
      {
        path: 'hjem',
        loadChildren: () => import('./dashboard/dashboard.module').then(mod => mod.DashboardModule),
        canLoad: [PrivacyGuard, AuthGuard]
      }, {
        path: 'faktura',
        loadChildren: () => import('../../invoice/invoice.module').then(mod => mod.InvoiceModule),
        canLoad: [PrivacyGuard, AuthGuard]
      }, {
        path: 'skade',
        loadChildren: () => import('./si-claims/si-claims.module').then(mod => mod.SiClaimsModule),
        canLoad: [PrivacyGuard, AuthGuard]
      }, {
        path: 'forsikringer',
        loadChildren: () => import('../../policy/policy.module').then(mod => mod.PolicyModule),
        canLoad: [PrivacyGuard, AuthGuard]
      }, {
        path: 'messages',
        loadChildren: () => import('../../message/message.module').then(mod => mod.MessageModule),
        canLoad: [PrivacyGuard, AuthGuard]
      }, {
        path: 'dokumenter',
        loadChildren: () => import('../../document/document.module').then(mod => mod.DocumentModule),
        canLoad: [PrivacyGuard, AuthGuard]
      }, {
        path: 'skade',
        loadChildren: () => import('./si-claims/si-claims.module').then(mod => mod.SiClaimsModule),
        canLoad: [PrivacyGuard, AuthGuard]
      }, {
        path: 'tilbud',
        loadChildren: () => import('../../quote/quote.module').then(mod => mod.QuoteModule),
        canLoad: [PrivacyGuard, AuthGuard]
      }, {
        path: 'profil',
        loadChildren: () => import('../ao/ao-profile/ao-profile.module').then(mod => mod.AoProfileModule),
        canLoad: [PrivacyGuard, AuthGuard]
      }, {
        path: 'mine-samtykker',
        loadChildren: () => import('./si-privacy-settings/si-privacy-settings.module').then(mod => mod.SiPrivacySettingsModule),
        canLoad: [PrivacyGuard, AuthGuard]
      }, {
        path: 'samtykke',
        loadChildren: () => import('./si-initial-settings/si-initial-settings.module').then(mod => mod.SiInitialSettingsModule),
        canLoad: [AuthGuard]
      }
      //, {
      //  path: 'samtykke',
      //  loadChildren: () => import('./ao-initial-settings/ao-initial-settings.module').then(mod => mod.AoInitialSettingsModule),
      //  canLoad: [AuthGuard]
      //}
    ]
  },
  {
    path: 'agent',
    component: AgentLayoutComponent,
    data: { roles: [Role.Representative, Role.Admin] },
    children: [{
      path: '',
      loadChildren: () => import('../../agent/agent.module').then(mod => mod.AgentModule)
    }]
  },
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () => import('../../login-selection/login-selection.module').then(mod => mod.LoginSelectionModule)
      }
    ]
  },
  //{
  //  path: 'admin',
  //  component: AdminComponent,
  //  children: [
  //    {
  //      path: '',
  //      loadChildren: () => import('../../admin/admin.module').then(mod => mod.AdminModule),
  //    }
  //  ]
  //},
  { path: '**', redirectTo: '', pathMatch: 'full' }
];
