import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Router, NavigationStart } from '@angular/router';
import { UserActivityService } from './services/user-activity.service';
import { UserActivityRequest } from './models/user-activity-request';
import { environment } from '../environments/environment';
import { DOCUMENT } from '@angular/common';
import { TenantService } from './services/tenant.service';
import { TenantConfig } from './models/tenant-config.model';
import { Subscription } from 'rxjs';
import { TenantConfigService } from './services/tenant-config.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'IPM Portal';
  configReady = false;
  tenantConfig: TenantConfig;

  constructor(private translateService: TranslateService,
    private router: Router,
    private tenantService: TenantService,
    private tenantConfigService: TenantConfigService,
    private titleService: Title,
              @Inject(DOCUMENT) private document: Document,
              private userTracking: UserActivityService) {
    moment.locale('nb');
    const language = 'no';
    this.tenantConfig = this.tenantConfigService.getConfig();
    this.translateService.setDefaultLang(language);
    this.translateService.currentLang = language;
    this.router.events.subscribe(
      (event) => {
        if (event instanceof NavigationStart) {
          this.userTracking.record(new UserActivityRequest('PageView', event.url));
        }
      });
  }

  loadStyle(styleName: string) {

    const head = this.document.getElementsByTagName('head')[0];

    const cssTag = this.document.getElementById(
      'main'
    ) as HTMLLinkElement;
    if (cssTag) {
      cssTag.href = `${styleName}.css`;
    } else {
      const style = this.document.createElement('link');
      style.id = 'main';
      style.rel = 'stylesheet';
      style.href = `${styleName}.css`;

      head.appendChild(style);
    }
  }

  ngOnInit() {

    if (environment.production) {
      if (location.protocol === 'http:') {
        window.location.href = location.href.replace('http', 'https');
      }
    }

    this.loadStyle(this.tenantConfig.key);
    this.titleService.setTitle(this.tenantConfig.name);

  }

  ngOnDestroy(): void {
  }
}
